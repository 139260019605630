import Mailcheck from 'mailcheck'
import {
  EMAIL_DOMAINS,
  EMAIL_SECOND_LEVEL_DOMAINS,
  EMAIL_TOP_LEVEL_DOMAINS,
} from '~/utils/constants'

export default {
  methods: {
    checkEmail() {
      Mailcheck.run({
        email: this.email,
        domains: EMAIL_DOMAINS,
        secondLevelDomains: EMAIL_SECOND_LEVEL_DOMAINS,
        topLevelDomains: EMAIL_TOP_LEVEL_DOMAINS,
        suggested: (suggestion) => {
          this.suggestedEmail = suggestion.full
        },
        empty: () => {
          this.suggestedEmail = ''
        },
      })
    },
    updateEmail() {
      this.email = this.suggestedEmail
      this.suggestedEmail = ''
    },
  },
}
