<template>
  <div v-if="suggestedEmail" class="bc-mt-6 d-flex flex-wrap">
    <span>{{ $label('form.suggestedEmail') }}&nbsp;</span>
    <BaseButton
      variant="link"
      class="suggested-email-btn reset-btn-styling bdrs-0 overflow-hidden"
      @click="updateEmail"
    >
      <span class="email">{{ suggestedEmail }}</span>
      <span class="question-mark">&nbsp;?</span></BaseButton
    >
  </div>
</template>

<script>
export default {
  props: {
    suggestedEmail: {
      type: String,
      default: '',
    },
    updateEmail: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  white-space: normal;
  word-break: break-all;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
}

.email {
  &:hover {
    text-decoration: underline;
  }
}

.question-mark {
  color: $grey-800;

  &:hover {
    text-decoration: none !important;
  }
}
</style>
